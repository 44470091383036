import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class ObscenityVoter extends React.Component {
  state = {
    loading: false,
    obscenity: {},
    errors: null,
    showScenario: false
  }

  componentDidMount() {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    this.fetchObscenity();
  }

  componentDidUpdate(prevProps, prevState) {
    // Because the text will cause the obscenity content to be different heights
    // the height needs to be adjusted so that the content doesnt spill out.
    // Setting the height to be the height og the react-reveal container
    if(prevState.obscenity.content !== this.state.obscenity.content){
      const height = document.getElementsByClassName('react-reveal')[0].offsetHeight+'px';
      document.getElementsByClassName('react-reveal')[0].parentElement.style.height = height;
      document.getElementsByClassName('react-reveal')[0].parentElement.style.transition = '250ms ease 0ms';
      document.getElementById('loading-spinner').style = null;
    }
  }

  fetchObscenity = () => {
    this.setState({
      loading: true,
      obscenity: {},
      showScenario: false
    });
    sleep(1000).then(() => {
      axios
      // The API we're requesting data from
      .get(`/votes/obscenity.json`)
      // Once we get a response, we'll map the API endpoints to our props
      .then(response =>
        response.data.obscenity
      )
      // Let's make sure to change the loading state to display the data
      .then(obscenity => {
        this.setState({
          obscenity,
          loading: false,
          showScenario: true
        });
        document.activeElement.blur();
        const generatorContainer = document.getElementById('obscenity-generator');
        generatorContainer.scrollIntoView({
          alignToTop: true,
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      })
      // We can still use the `.catch()` method since axios is promise-based
      .catch(error => this.setState({ error, loading: false }));
    });

  }

  voteObscenity = (vote) => {
    this.setState({
      loading: true
    });

    axios
    .post('/votes/vote.json',{
      vote: {
        id: this.state.obscenity.id,
        vote: vote
      }
    })
    .then(response =>
      response.data.obscenity
    )
    .then(obscenity => {
      this.setState({
        obscenity,
        loading: false
      });
      // sleep(1000).then(() => {
      //   this.fetchObscenity();
      // });
    })
    .catch(error => this.setState({ error, loading: false }));
  }

  voteLike = () => {
    this.voteObscenity('like');
  }

  voteDislike = () => {
    this.voteObscenity('dislike');
  }

  favouriteFavourite = (e) => {
    this.setState({
      loading: true
    });

    axios
    .post('/favourites.json',{
      obscenity_id: this.state.obscenity.id
    })
    .then(response =>
      response.data
    )
    .then(data => {
      this.setState({
        favourite: data.favourite,
        obscenity: data.obscenity,
        favourited: data.obscenity.favourited,
        loading: false
      });
    })
    .catch(error => this.setState({ error, loading: false }));
  }

  deleteFavourite = (e) => {
    this.setState({
      loading: true
    });

    axios
    .delete(`/favourites/${this.state.obscenity.favourite_id}.json`)
    .then(response =>
      response.data.obscenity
    )
    .then(obscenity => {
      this.setState({
        obscenity,
        loading: false
      });
    })
    .catch(error => this.setState({ error, loading: false }));
  }

  render () {
    const { loading, obscenity, showScenario } = this.state;
    const favourited = obscenity.favourited;
    const loggedIn = obscenity.logged_in;
    const favouriteIconClass = favourited ? 'fas fa-heart' : 'far fa-heart';
    const likeIconClass = obscenity.liked ? 'fas fa-thumbs-up' : 'far fa-thumbs-up';
    const dislikeIconClass = obscenity.disliked ? 'fas fa-thumbs-down' : 'far  fa-thumbs-down';
    const favouriteButtonFunction = favourited ? this.deleteFavourite : this.favouriteFavourite;

    if(loggedIn === false) {
      return (
        <div className='obscenity-generator' id='obscenity-generator'>
          <div className='loading-area'>
            <div id='loading-spinner' className='spinner-border spinner-border-lg text-primary loading-spinner' style={{'display': 'none'}} role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <Fade left opposite collapse when={true}>
              <div className='alert alert-dark' role='alert'>
                <div className='text-center'>
                  <a href='/users/sign_in'>Signin</a> or <a href='/users/sign_up'>Signup</a> to favourite for later...
                </div>
              </div>
            </Fade>
          </div>
        </div>
      );
    }

    let classes = 'fas fa-redo';
    if(loading){
      classes = 'fas fa-redo spinner';
    }

    let content = null;
    if(obscenity && Object.keys(obscenity).length === 0) {
      content = (
        <div className='alert alert-dark' role='alert'>
          No obscenities to vote
        </div>
      );
    }
    if(obscenity && Object.keys(obscenity).length > 0) {
      content = (
        <div className='alert alert-dark' role='alert'>
          <div className='row'>
            <div className='col-md-10'>
              <div>
                <span className='badge badge-primary' style={{'marginBottom': '10px'}}>Universe: {obscenity.universe}</span>
                <span className='badge badge-secondary' style={{'marginBottom': '10px', 'marginLeft': '5px'}}>{obscenity.scenario_number}</span>
                <blockquote className='blockquote text-center'>
                  <p className='mb-0' style={{fontSize: '1rem', paddingBottom: '10px'}}>{obscenity.content}</p>
                  <footer className='blockquote-footer'>'{obscenity.title}' written by <cite title={obscenity.author}>{obscenity.author}</cite></footer>
                </blockquote>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='row'>
                <div className='col-4 col-sm-4 col-md-12'>
                  <button type='button' className='btn btn-block btn-lg btn-light' id='like' onClick={this.voteLike} style={{'marginBottom': '10px'}}>
                    <span className={likeIconClass}></span>
                    <span>{obscenity.cached_votes_up}</span>
                  </button>
                </div>
                <div className='col-4 col-sm-4 col-md-12'>
                  <button type='button' className='btn btn-block btn-lg btn-light' id='dislike' onClick={this.voteDislike} style={{'marginBottom': '10px'}}>
                    <span className={dislikeIconClass}></span>
                    <span>{obscenity.cached_votes_down}</span>
                  </button>
                </div>
                <div className='col-4 col-sm-4 col-md-12'>
                  <button className='btn btn-block btn-lg btn-primary' onClick={favouriteButtonFunction} style={{'marginBottom': '10px'}}>
                    <span className={favouriteIconClass}></span>
                    <span>{obscenity.favourited_count}</span>
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <button className='btn btn-block btn-lg btn-success' onClick={this.fetchObscenity} style={{'marginBottom': '10px'}}>
                    <span className={classes}></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='obscenity-generator' id='obscenity-generator'>
        <div className='loading-area'>
          <div id='loading-spinner' className='spinner-border spinner-border-lg text-primary loading-spinner' style={{'display': 'none'}} role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <Fade left opposite collapse when={showScenario}>
            {content}
          </Fade>
        </div>
      </div>
    );
  }
}

export default ObscenityVoter
