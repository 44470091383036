import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class ObscenityGenerator extends React.Component {
  static propTypes = {
    scenarios: PropTypes.number
  }

  static defaultProps = {
    scenarios: 0
  }

  state = {
    loading: false,
    obscenity: {},
    errors: null,
    favourited: false,
    showScenario: false,
    showScenarioButtons: false,
    selectedScenario: null
  }

  componentDidMount() {
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  }

  componentDidUpdate(prevProps, prevState) {
    // Because the text will cause the obscenity content to be different heights
    // the height needs to be adjusted so that the content doesnt spill out.
    // Setting the height to be the height og the react-reveal container
    if(prevState.obscenity.content !== this.state.obscenity.content){
      const height = document.getElementsByClassName('react-reveal')[1].offsetHeight+'px';
      document.getElementsByClassName('react-reveal')[1].parentElement.style.height = height;
      document.getElementsByClassName('react-reveal')[1].parentElement.style.transition = '250ms ease 0ms';
      document.getElementById('loading-spinner').style = null;
    }
  }

  fetchObscenity = () => {
    this.setState({
      loading: true,
      obscenity: {},
      showScenario: false
    });
    sleep(1000).then(() => {
      axios
      // The API we're requesting data from
      .get(`/obscenities/new.json?scenario_number=${this.state.selectedScenario}`)
      // Once we get a response, we'll map the API endpoints to our props
      .then(response =>
        response.data.obscenity
      )
      // Let's make sure to change the loading state to display the data
      .then(obscenity => {
        this.setState({
          obscenity,
          loading: false,
          favourited: false,
          showScenario: true
        });
        const generatorContainer = document.getElementById('obscenity-generator');
        generatorContainer.scrollIntoView({
          alignToTop: true,
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      })
      // We can still use the `.catch()` method since axios is promise-based
      .catch(error => this.setState({ error, loading: false }));
    });

  }

  favouriteObscenity = (e) => {
    this.setState({
      loading: true
    });

    axios
    .post('/obscenities.json',{
      obscenity: {
        content: this.state.obscenity.content,
        author: this.state.obscenity.author,
        title: this.state.obscenity.title,
        universe: this.state.obscenity.universe,
        scenario_number: this.state.obscenity.scenario_number
      }
    })
    .then(response =>
      response.data.obscenity
    )
    .then(obscenity => {
      this.setState({
        obscenity,
        loading: false,
        favourited: true
      });
    })
    .catch(error => this.setState({ error, loading: false }));
  }

  deleteObscenity = (e) => {
    this.setState({
      loading: true
    });

    axios
    .delete(`/obscenities/${this.state.obscenity.id}.json`)
    .then(response =>
      this.setState({
        loading: false,
        favourited: false
      })
    )
    .catch(error => this.setState({ error, loading: false }));
  }

  toggleScenarioButtons = () => {
    const newToggleState = !this.state.showScenarioButtons;
    let selectedScenario = this.state.selectedScenario;
    if(newToggleState === false){
      selectedScenario = null;
    }

    this.setState({
      showScenarioButtons: newToggleState,
      selectedScenario
    });
  }

  selectScenario = (e) => {
    const id = parseInt(e.target.id);

    this.setState({
      selectedScenario: id
    });
    this.fetchObscenity();
  }

  clearScenario = () => {
    this.setState({
      selectedScenario: null,
      showScenarioButtons: false
    });
    this.fetchObscenity();
  }

  render () {
    const { loading, favourited, obscenity, selectedScenario, showScenario, showScenarioButtons } = this.state;
    const loggedIn = obscenity.logged_in === true;
    const favouriteIconClass = favourited ? 'fas fa-heart' : 'far fa-heart';
    const favouriteButtonFunction = favourited ? this.deleteObscenity : this.favouriteObscenity;

    let favouriteButton = (
      <div>
        <a href='/users/sign_in'>Signin</a> or <a href='/users/sign_up'>Signup</a> to favourite for later...
      </div>
    );

    if(loggedIn){
      favouriteButton = (
        <button className='btn btn-lg btn-primary btn-block' onClick={favouriteButtonFunction}>
          <span className={favouriteIconClass}></span>
        </button>
      );
    }

    let classes = "fas fa-redo";

    if(loading){
      classes = "fas fa-redo spinner";
    }

    let scenarios = [];
    for(let i=1; i<= this.props.scenarios; i++){
      let classes = 'btn btn-secondary';
      if(i === selectedScenario){
        classes = 'btn btn-secondary active';
      }

      scenarios.push(
        <button type='button' className={classes} key={`scenario_${i}`} onClick={this.selectScenario} id={i}>
          {i}
        </button>
      );
    }

    return (
      <div className='obscenity-generator' id='obscenity-generator'>
        <div className='row'>
          <div className='col-md-2'>
            <button className='btn btn-info' onClick={this.toggleScenarioButtons} style={{'marginBottom': '10px'}}>Scenarios</button>
          </div>
          <div className='col-md-10'>
            <Fade right collapse when={showScenarioButtons}>
              <div className='text-right' style={{'marginBottom': '10px'}}>
                <div className='btn-group flex-wrap' role='group' aria-label='scenario picker buttons'>
                  {scenarios}
                  <button type='button' className='btn btn-secondary' key='clear_scenario' onClick={this.clearScenario}>
                    <span className='fas fa-times-circle'/>
                  </button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div id='loading-area' className='loading-area'>
          <div id='loading-spinner' className='spinner-border spinner-border-lg text-primary loading-spinner' style={{'display': 'none'}} role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <Fade left opposite collapse when={showScenario}>
            <div className='alert alert-dark loading-content' role='alert'>
              <div className='row'>
                <div className='col-md-10'>
                  <div>
                    <span className='badge badge-primary' style={{'marginBottom': '10px'}}>Universe: {obscenity.universe}</span>
                    <span className='badge badge-secondary' style={{'marginBottom': '10px', 'marginLeft': '5px'}}>{obscenity.scenario_number}</span>
                    <blockquote className='blockquote text-center'>
                      <p className='mb-0' style={{fontSize: '1rem', paddingBottom: '10px'}}>{obscenity.content}</p>
                      <footer className='blockquote-footer'>'{obscenity.title}' written by <cite title={obscenity.author}>{obscenity.author}</cite></footer>
                    </blockquote>
                  </div>
                </div>
                <div className='col-md-2'>
                  {favouriteButton}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className='action'>
          <div className='row'>
            <div className='col-md-12'>
              <button className='btn btn-lg btn-success btn-block' onClick={this.fetchObscenity}>
                Retrieve Obscenity <span className={classes}></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ObscenityGenerator
